import React from 'react';

interface ButtonProps {
  text: string; 
  onClick?: () => void;
  disabled?: boolean; 
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled = false,
  className,
}) => {
  return (
    <button
      className={`button add ${className}`}
      onClick={onClick}
      disabled={disabled}
      >
      {text}
    </button>
  );
};

export default Button;
