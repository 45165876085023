import React, { useState } from "react";
import "../styles/BottomMenu.css";
import Button from "./Button";
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_PROJECT_URL!;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY!;
const supabase = createClient(supabaseUrl, supabaseKey);

interface ModalFormProps {
  onClick?: () => void;
}

const ModalForm: React.FC<ModalFormProps> = ({ onClick }) => {
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [status, setStatus] = useState<string>("");

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (validateEmail(emailValue)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const { data, error } = await supabase
        .from("waitlist")
        .insert([
          { email: email } 
        ]);

      if (error) {
        setStatus(`Error: ${error.message}`);
      } else {
        setStatus("Email submitted successfully!");
        setEmail(""); 
      }
    } catch (err) {
      setStatus(`Error: ${err}`);
    }
  };

  return (
    <>
      <h3>We're full, but you’re next!</h3>
      <p>
        Enter your email to join the waiting list and get notified when a spot
        opens up!
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
        />
        <Button
          text="Join the Waiting List"
          className={"add"}
          disabled={disabled}
        />
      </form>
      {status && <p>{status}</p>}
    </>
  );
};

export default ModalForm;
