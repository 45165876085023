import React from "react";
import '../styles/BottomMenu.css'

interface BottomMenuProps {
  onClick?: () => void;
}

const BottomMenu: React.FC<BottomMenuProps> = ({onClick}) => {
  return (
    <button className="bottom-menu" onClick={onClick}>
      <button onClick={onClick} className="menu-button">Create your account</button>
    </button>
  );
};

export default BottomMenu;

