import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "../styles/ProfilePage.css";
import BottomMenu from "../components/BottomMenu";
import Modal from "../components/Modal";
import ModalForm from "../components/ModalForm";
import Main from "../components/Main";

const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="profile-page">
      <Navbar />
      <div className="grid">
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalForm/>
        </Modal>
        <Main />
      </div>
      <BottomMenu onClick={() => setIsModalOpen(true)} />
    </div>
  );
};

export default Profile;
