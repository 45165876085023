import React from "react";
import '../styles/Main.css';
import mainImage from '../images/mypicture.jpg'
import youtube from "../images/youtube.jpg"
import HandleAddLinks from '../components/HandleLinks'
import Link from "../components/Link";
import instagram from "../images/IG.jpeg"
import novela from "../images/novela.png"
import ffitboost from "../images/ffitboost.jpg"

const Main = () => {
  return (
    <div className="main">
        <div className="circular-image-container">
            <img className='main_image' src={mainImage} alt="Imagen" />
        </div>
        <div className="margin_left">
            <h3 className="profile-name">Meet Irene</h3>
            <p className="profile-description aligment_left">Link to my favorite things</p>
        </div>
        <HandleAddLinks/>
        <div className="link-seccion">
        <Link
         imageURL={ffitboost}
         linkURL={"https://www.ffitboost.com"}
         onClick={() => {}} 
         title={"The Ultimate Fitness App"}  
         subtitle={"Personalized workouts, smart tracking, and real results—built for your success."}
        />
        <Link
         imageURL={instagram}
         linkURL={"https://www.instagram.com/irene_mmenendez/"}
         onClick={() => {}} 
         title={"@irene_mmenendez"}  
         subtitle={"Deep into my life on my Instagram profile"}
        />
        <Link
         imageURL={youtube}
         linkURL={"https://www.youtube.com/channel/UCVmstJqtcXM_T_5VOkn0KnA?sub_confirmation=1"}
         onClick={() => {}} 
         title={"Check out my YouTube Channel!"}
         subtitle={"Subscribe for exclusive content, tips, and more!"}
        />
        <Link
         imageURL={novela}
         linkURL={"https://www.amazon.es/2053-secretos-muerte-quedan-revelados/dp/B087SDLTYN/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1589484830&sr=8-1"}
         onClick={() => {}} 
         title={"My Novel"}  
         subtitle={"A novel that will challenge your beliefs and change the way you see destiny"}
        />
        </div>
    </div>
  );
};

export default Main;