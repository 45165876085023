import React from 'react';
import "../styles/Link.css"

interface LinkProps {
  imageURL: string; 
  linkURL: string;
  onClick?: () => void;
  title: string; 
  subtitle?: string; 
}

const Link: React.FC<LinkProps> = ({
  imageURL,
  linkURL,
  onClick,
  title,
  subtitle,
}) => {
    return (
        <a
        href={linkURL}
        target="_blank"
        rel="noopener noreferrer"
        className="link-card"
      >
        <img src={imageURL} alt="image-link" className="link-image" />
        <div className="link-text">
          <span className="link-title">{title}</span>
          <span className="link-subtitle">{subtitle}</span>
        </div>
      </a>
      );
    };

export default Link;
