import React, {useState} from 'react';
import Button from './Button';
import '../styles/HandleLinks.css'

const HandleAddLinks: React.FC = () => {
const [copied, setCopied] = useState(false);
  const handleShare = () => {
    const url = window.location.href; 
    navigator.clipboard.writeText(url) 
      .then(() => {
        setCopied(true); 
        setTimeout(() => setCopied(false), 2000); 
      })
  };

  return (
    <div className="flex flex-col items-center button_group">
      <Button 
        text="Follow" 
        className={"add"} 
      />
      <Button 
        text={copied ? 'Link Copied!' : "Share"}
        onClick={handleShare} 
        className={"share"} 
      />
    </div>
  );
};

export default HandleAddLinks;
