import React from "react";
import "../styles/Navbar.css";
import logo from "../images/logo.jpg"

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <img className="logo" alt="logo" src={logo}/>
      <h1 className="profile-title text_left">MeetU</h1>
      <div></div>
    </nav>
  );
};

export default Navbar;
